.h1 {
  color: #fff;
  font-size: 45px;
  font-weight: bold;
  line-height: 48px;
  text-transform: none !important;
  margin-bottom: 20px;
}

.h2 {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 32px;
  margin: 0 0 24px;
}

.subheader {
  font-size: 26px;
  font-weight: 300;
  color: #fff;
  margin: 0 0 24px;
}

.p {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  line-height: 32px;
  margin: 0 0 24px;
  text-align: justify;
}
