
.homeintro{
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      height: 100vh;
}

.button{
    font-family: 'Vazir', sans-serif;
}