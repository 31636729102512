* {margin: 0; padding: 0;}
#container {height: 100%; width:100%;}
#left1, #right1 {display: inline-block; *display: inline; zoom: 1; vertical-align: top; }
#left1 {width: 45%; margin-top:10px; margin-bottom:50px; margin-right: 30px;}
#right1 {width: 45%; margin-top:10px; margin-bottom:50px}

.a_1 {
    text-decoration: none;
    display: inline-block;
    padding: 8px 16px;
  }
  
  .a_1:hover {
    background-color: #ddd;
    color: black;
  }
  
  .previous {
    background-color: #f1f1f1;
    color: black;
  }
  
  .next {
    background-color: #04AA6D;
    color: white;
  }
  
  .round {
    border-radius: 50%;
  }