header{
    position        : sticky;
    top             : 0;
    z-index         : 999;
  }
  nav{
    display         : flex;
    flex-flow       : row wrap;
  
    text-transform  : capitalize;
  
    padding         : 0.5em 1em;
  
    justify-content : space-between;
  
    background-color:#222325;
  
    backdrop-filter : blur(5px);
    color           : white;
  }
  
  nav div.navbar-links{
    display         : flex;
    flex-flow       : row wrap;
  }
  nav div.navbar-links>div.globe{
    padding         : 0.5em;
    text-align      : center;
  
    background-color: rgba(0, 0, 0, 0);
    
    display         : flex;
    flex-flow       : column wrap;
    justify-content : center;
  }
  nav div.navbar-links>div.globe:hover{
    background-color: rgba(0, 0, 0, 0.2);
    cursor          : pointer;
  }
  nav div.navbar-links>div{
    margin-right    : 2em;
    padding         : 0.5em 3em;
    text-align      : center;
  
    background-color: rgba(0, 0, 0, 0);
    
    display         : flex;
    flex-flow       : column wrap;
    justify-content : center;
  }
  nav div.navbar-links>div:hover{
    background-color: rgba(0, 0, 0, 0.2);
    cursor          : pointer;
  }
  
  nav div.navbar-links>div>p{
    font-weight     : 500;
    text-transform  : capitalize;
    margin          : 0;
    font-size       : 1.0em;
  }
  
  nav div.navbar-title{
    display         : flex;
    flex-flow       : column wrap;
    justify-content : center;
  }
  
  nav div.navbar-title p{
    font-size       : 2em;
    margin          : 0;
    font-weight     : 700;
  }